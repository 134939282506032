:root{
  --themeclr1: #004071;
  --themeclr2: #f11b1b;
}

div#root {
  overflow-y: auto;
  max-height: 100vh;
}

h1, p {
  font-family: Lato;
}
header.main-header{
  
  color: #ffffff;
  
}
.veriFy{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.7);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.veriFy .close{
  
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  background: #d61f24;
  border-radius: 50%;
  padding: 5px 10px 5px 11px;
  color: #fff;
  font-weight: bold;
}
.img_responsive{
  max-width:100%;
}
.verification{
  padding: 15px;
  background: #ffffff;
  -webkit-box-shadow: 0 0 8px 1px #888888;
box-shadow: 0 0 8px 1px #888888;
}
.validate{
  background: var(--themeclr1);
  color: #ffffff;
}
.validate:hover{
  color: var(--themeclr1);
  background: #ffffff;
  border-color: var(--themeclr1);
}
.btn-light-transparent{
  background: none;
  border: none;
  color: #ffffff;
}

.btn-light-transparent:hover{
  background: #ffffff;
  border: none;
  color: var(--themeclr1);
}

pre{
  border: 1px dashed #333333;
  margin: 5px;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
}
.preloader{
  background: #DC143C;
  color: #ffffff;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.row.person {
  padding: 10px;
  margin: 10px;
  border: 1px dashed;
}
.row.ticket-heading{
  padding: 10px;
  margin: 10px;
  border: 1px dashed;
  background-color: var(--themeclr1);
  color: #fff;
}
ul.list-inline {
  display: flex;
  justify-content: space-evenly;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}

header.main-header .navigation{
  position: relative;
  z-index: 9;
  background: var(--themeclr1);
  
}

.second-header {
  position: fixed;
  z-index: 999;
  max-width: 300px;
  left: 10px;
  bottom: 10px;
  color:#fff;
}

.second-header .list-toggler{
  position: absolute;
  bottom: 100%;
  right: 0%;
  padding: 10px;
}

.second-header .list-toggler.closed {
  left: 100%;
  right: auto;
}

.second-header .frow {
  background: rgba(0,0,0,.7);
  text-align: center;
  padding: 15px;
}

.second-header .nav-link, .second-header a{
  
  color: #004071;
}

.second-header .nav-link:hover, .second-header .nav-link:focus, .second-header a:hover,.second-header a:focus{
  text-decoration: underline;
  
  color: #004071;
}

.list-group{
  list-style: none;
}

.list-item {
  padding: 5px 15px;
  border: 1px solid rgba(255,255,255,0.3);
  margin: 3px;
  background: #fff;
}

.list-item:last-child{
  background: none;
  border: none;
}

.multi-sub-holder{
  width: 100%;
}
.sub-menu-holder {
display: flex;
color: #fff;
padding: 15px;
width: 100%;
text-align: center;
justify-content: center;
}
.sub-menu-holder a {
color: #fff;
font-size: 13.3333px !important;
width: max-content;
padding: 0 15px;
}

.sub-menu-holder a:hover {
color: #F4B6D1;
}


header.main-header .navigation.sticky {
  width: 100%;
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 9999;
}

header.main-header:hover .navigation, header.main-header:focus .navigation{
  background:rgb(0 64 114 / 90%);
}

.logoImg{
  min-width: 15vw;
}

.MainSlider{
  position: relative;
  
  z-index: 0;
}
.container{
  position: relative;
}
.LoginScreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 8;
  padding-top: 90px;
}

.LoginScreen .container{
  position: relative;
}
.LoginScreen .container .closebtn{
  position: absolute;
  right: 35px;
  top: 0px;
  text-decoration: none;
  cursor: pointer;
  color: #000;
  font-size: 27px;
}
#UserLogin{
  display: flex;
  height: 100%;
  padding: 90px 0;
  overflow: auto;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255);
  font-weight: 400;
}


.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgb(255 255 255 / 70%);
}

.navbar-light .navbar-nav .nav-link.nav-btn{
  margin: 0% 10px;
  font-weight: 500;
  color: var(--themeclr1);
}

.player-wrapper {
  width: 100%;
  position: relative;
  height: 100vh;
}

.player-wrapper.demo::after{
  
  content: " Video Placeholder for Museam OR 360 View";
  text-align: center;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: rgba(255,255,255,0.5);
  top: 0;
  background: rgba(0,0,0,0.3);
  font-size: 5vw;
}

.player-wrapper .video {
  width: 100%;
  height: 100%;
}

section{
  padding: 1rem;
}

.section-heading, .box-heading
{
  color: var(--themeclr1);
  padding: 0 0 30px 0;
}

.text-theme-1{
  color: var(--themeclr1) !important;
}
.text-theme-2{
  color: var(--themeclr2) !important;
}
.bg-theme-1{
  background-color: var(--themeclr1) !important;
  color: #ffffff;
}
.bg-theme-2{
  background-color: var(--themeclr2) !important; color: #ffffff;
}
.btn-theme-1{
  background-color: var(--themeclr1); border: 1px solid var(--themeclr1); color: #ffffff;
}
.btn-theme-1:hover{
  color: var(--themeclr1);
  background-color: #ffffff;
}
.btn-theme-2{
  background-color: var(--themeclr2) !important; color: #ffffff;
}
.bg-grey{
  background-color: #e9e9e9;
}
.heading{
  color: var(--themeclr1);
}
.booking-content{
  display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: .6rem;
}
.w70{
  max-width: 70%;
}
.section-heading span, .box-heading span{
  position: relative;
}
.section-heading span::after{
  content: " ";
    position: absolute;
    width: 50%;
    top: 150%;
    right: 25%;
    height: 2px;
    left: 25%;
    margin: auto;
    background-color: var(--themeclr2);
}
.box-heading span::after{
  content: " ";
    position: absolute;
    width: 30%;
    height: 1px;
    top: 150%;
    left: 0%;
    background-color: var(--themeclr2);
}

.react-datepicker.customPicker .react-datepicker__month-container{
  float: none;
  width: 100%;
}
.react-datepicker.customPicker{
  width: 100%;
  border-radius: 0;
}

.react-datepicker.customPicker .react-datepicker__current-month {
  text-align: left;
  padding: 1rem 2rem;
}
.react-datepicker.customPicker .react-datepicker__day-name{
  visibility: hidden;
  font-size: 0;
}
.react-datepicker.customPicker .react-datepicker__day-name:first-letter{
  visibility: visible;
  font-size: 1rem;
}
.react-datepicker.customPicker .react-datepicker__header{
  background: none;
  border-radius: 0;
  border: none;
}
.react-datepicker.customPicker .react-datepicker__current-month{
  text-transform: uppercase;
}
.react-datepicker.customPicker .react-datepicker__current-month span {
  font-size: 2rem;
}
.react-datepicker.customPicker .react-datepicker__navigation{
  top:7%;
}
.react-datepicker.customPicker .react-datepicker__navigation-icon::before{
  
  height: 10px;
  width: 10px;border-width: 2px 2px 0 0;
  border-color: #212529;
}
.react-datepicker.customPicker .react-datepicker__navigation--next{
  right: 2vw;
}
.react-datepicker.customPicker .react-datepicker__navigation--previous{
  right: 9%;
  left: auto;
}
.react-datepicker.customPicker  .react-datepicker__day--outside-month{
  visibility: hidden;
}
.react-datepicker.customPicker  .react-datepicker__day-names,
.react-datepicker.customPicker  .react-datepicker__week{
  display: flex;
  justify-content: space-around;
}

.react-datepicker.customPicker .react-datepicker__day {
  border: 1px solid #212529;
  position: relative;
  display: grid;
  min-width: 3rem;
  align-items: center;
  align-self: center;
  min-height: 3rem;
  font-weight: bold;
  justify-self: center;
}

.react-datepicker.customPicker .react-datepicker__day:hover, 
.react-datepicker.customPicker .react-datepicker__day--selected {
  border-radius: 0%;
}

.react-datepicker.customPicker .react-datepicker__day--disabled {
  background-color: #e9e9e9; color: #212529; cursor: not-allowed;
}

.react-datepicker.customPicker .react-datepicker__day--selected {
  background-color: var(--themeclr1);
}

.react-datepicker.customPicker .react-datepicker__day--selected:hover {
  background-color: #216ba5;
}
.socialshare ul {
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: space-around;
  text-align: center;
  color: var(--themeclr1);
}
.socialshare ul li{
  cursor: pointer;
}
.reachby{
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.reachby li{
  position: relative;
  padding: 1rem;
  background-color: #e9e9e9;
}
.rb-title .heading{
  padding-top: 6px;
  float: left;
}
.rb-title span{
  padding-top: 6px;
  float: right;
}
span.rt-icon {
  background: #2699fb;
  padding: 0.5rem;
  color: #fff;
  position: absolute;
  border-radius: 50%;
  left: 10px;
}

footer{
  background-color: var(--themeclr1);
  color: #ffffff;
}
footer .footer-content{
  padding: 3rem 1rem 3rem 1rem;
}
footer a{
  color: #ffffff;
  text-decoration: none;
}
footer a:hover{
  color: #eeeeee;
  text-decoration: underline;
}
footer .heading{
  margin-bottom: 40px;
}
footer .heading i{
 color: #96b0c4;
}
footer .heading span{
  color: #de99f9;
  padding: 1rem 0;
  border-bottom: 1px solid #ffffff;
}
footer .navmenu{
  padding: 0;
  list-style: none;
}
@media screen and (min-width:1600px) {
  html,body{
    font-size: .9vw;
  }
  .logoImg{
    min-width: auto;
    max-width: 100%;
  }
  .react-datepicker.customPicker .react-datepicker__day {
    min-width: 3vw;
    min-height: 3vw;
    font-size: 1rem;
  }
  header.main-header .navigation{
    font-size: .8vw;
  }
}
@media screen and (max-width: 1024px) {
  .logoImg{
    min-width: 200px;
    max-width: 100%;
  }
  .w70{
    max-width: 100%;
  }
  .navbar-light .navbar-nav .nav-link.nav-btn {
    margin: 10px 0;
  }
  .navbar-light .navbar-toggler {
      color: rgb(255 255 255 / 100%);
      border-color: rgb(255 255 255 / 100%);
  }
}


@media screen and (max-width: 676px) {
  .player-wrapper{
    height: 40vh;
  }
  .verification{
    position: absolute;
    top: 100px;
  }
}
@media screen and (max-width: 444px) {
  .react-datepicker.customPicker .react-datepicker__day {
    min-width: 2vw;
    min-height: 2vh;
  }
}

@media print {
  header, footer, ul.list-inline{
    display: none;
  }
  .do-not-print{
    display: none;
  }
  .person{
    word-break: break-all;
  }
  .col-md-1,.col-md-2,.col-md-3,.col-md-4,
  .col-md-5,.col-md-6,.col-md-7,.col-md-8, 
  .col-md-9,.col-md-10,.col-md-11,.col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8%;
  }
  .col-md-2 {
    width: 16%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33%;
  }
  .col-md-5 {
    width: 42%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58%;
  }
  .col-md-8 {
    width: 66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83%;
  }
  .col-md-11 {
    width: 92%;
  }
  .col-md-12 {
    width: 100%;
  }
}